import axios from 'axios';
import { apiUrl } from "../config";

export const swapWallet = async (userId: number, coins: number, toCrypto: boolean) => {
    try {
        let url = ""
        if (toCrypto) {
            url = `${apiUrl}/wallets/exchange/coins-to-crypto`;
        }
        else {
            url = `${apiUrl}/wallets/exchange/crypto-to-coins`;
        }
        await axios.post(url, {
            "userId": userId,
            [toCrypto ? "coinsToExchange" : "cryptoCoinsToExchange"]: coins
        });
    } catch (error) {
        console.error('Error swapping, returning default response:', error);
    }
};