// @ts-ignore
import styles from "./Background.module.css";
import React from 'react';


const Background = () => {


    return (
        <div className={styles.container}>

        </div>
    );
};

export default Background;
