// @ts-ignore
import styles from "./FarmingButton.module.css";
import { useState, useEffect } from "react";
import React from 'react';
import { iconMask } from "../iconMask/iconMask";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { addCoins } from "../../store/slices/walletSlice";
import { RootState } from "../../store/store";
import { endFarm, startFarm, updateFarmingState } from "../../store/slices/farmingSlice";
import { FarmingState } from "../../enums/FarmingState";
import useTelegramUserId from "../../services/getUserId";

interface FarmingButtonProps {
    farmingPower: number;
    farmingScale: number;
    goElements: boolean;
}

const FarmingButton: React.FC<FarmingButtonProps> = ({ farmingPower, farmingScale, goElements }) => {
    const dispatch = useDispatch();
    const userId = useTelegramUserId();
    const { t } = useTranslation();
    const [earnings, setEarnings] = useState<number>(0);
    const [timeLeft, setTimeLeft] = useState<{ hours: number, minutes: number }>({ hours: 0, minutes: 0 });
    const farming = useSelector((state: RootState) => state.farming);
    const farmingTime = farming.farmingTime;

    // Перевіряємо наявність startTime та endTime та повертаємо 0, якщо їх немає
    const hasValidFarmingTime = farmingTime?.endTime && farmingTime?.startTime;

    const calculateEarnings = (): number => {
        if (!hasValidFarmingTime || !farmingTime?.endTime || !farmingTime?.startTime) return 0;
    
        const currentTimestamp = new Date().getTime();  // Use timestamp for current time
        const startTimestamp = new Date(farmingTime.startTime).getTime();  // Convert to timestamp if necessary
        const endTimestamp = new Date(farmingTime.endTime).getTime();      // Convert to timestamp if necessary
    
        // Якщо час закінчився
        if (currentTimestamp >= endTimestamp) {
            const deltaFarmTime = (endTimestamp - startTimestamp) / 1000;  // Calculate time in seconds
            return parseFloat((farmingPower * farmingScale * deltaFarmTime).toFixed(4));
        }
    
        const deltaFarmTime = (currentTimestamp - startTimestamp) / 1000;  // Calculate time in seconds
        return parseFloat((farmingPower * farmingScale * deltaFarmTime).toFixed(4));
    };
    
    const calculateTimeLeft = () => {
        if (!hasValidFarmingTime || !farmingTime?.endTime) return { hours: 0, minutes: 0 };
    
        const currentTimestamp = new Date().getTime();  // Current time as timestamp
        const endTimestamp = new Date(farmingTime.endTime).getTime();      // Convert to timestamp if necessary
    
        const difference = endTimestamp - currentTimestamp;
    
        if (difference <= 0) {
            return { hours: 0, minutes: 0 };
        }
    
        const hours = Math.floor(difference / (1000 * 60 * 60));  // Calculate hours left
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));  // Calculate minutes left
    
        return { hours, minutes };
    };

    const onClickFarm = () => {
        if (farming.farmingState === FarmingState.NotStarted) {
            dispatch(startFarm(userId))
        }
        else if (farming.farmingState === FarmingState.Finished) {
            const earning = calculateEarnings();

            dispatch(endFarm(userId))
            dispatch(addCoins(earning));
        }
    }

    useEffect(() => {
        if (!hasValidFarmingTime) return;

        const interval = setInterval(() => {
            setEarnings(calculateEarnings());
            setTimeLeft(calculateTimeLeft());

        }, 1000);

        return () => clearInterval(interval);
    }, [farmingTime?.endTime, farmingPower, farmingTime]);

    useEffect(() => {
        if (!hasValidFarmingTime) return;

        setEarnings(calculateEarnings());
        setTimeLeft(calculateTimeLeft());

    }, [farmingTime?.endTime, farmingPower, farmingTime]);

    return (
        <div className={` ${styles.FarmingContainer} ${!goElements ? styles.hidden : ""}`}>
            <div className={styles.FarmingButton} onClick={onClickFarm}>
                {(() => {
                    switch (farming.farmingState) {
                        case FarmingState.NotStarted:
                            return (
                                <div className={styles.StartFarming}>
                                    {t('start_farming')}
                                </div>
                            );

                        case FarmingState.Finished:
                            return (
                                <div className={styles.ClaimFarming}>
                                    <div className={styles.ClaimBtn}>
                                        {t('claim')}
                                    </div>
                                    <div className={styles.FarmingEarn}>
                                        {earnings}
                                    </div>
                                    <div className={styles.FarmingMoney} style={iconMask('images/money_base.png')} />
                                </div>
                            );

                        case FarmingState.InProgress:
                            return (
                                <div className={styles.TimeLeft}>
                                    <div className={styles.FarmingMoney} style={iconMask('images/money_base.png')} />
                                    <p className={styles.FarmingEarn}>{earnings}</p>
                                    <p className={styles.FarmingTimeLeft}>{timeLeft.hours}h {timeLeft.minutes}m</p>
                                </div>
                            );
                    }
                })()}
            </div>
        </div>
    );
};

export default FarmingButton;
