// @ts-ignore
import styles from "./HeaderWallet.module.css";
import { useState, useEffect } from "react";
import { getUser } from "../../services/getUser";
import { User } from '../../models/User';
import React from 'react';
import useTelegramUserId from "../../services/getUserId";
import useWallet from "../../hooks/useWallet";
import useUser from "../../hooks/useUser";
import { getBalance } from "../../utils";

// @ts-ignore
const HeaderPanel = ({wallet}) => {

    return (
        <div className={styles.headerPanel}>
            <div className={styles.MoneyBase}>
                <img src={'images/money_gold.png'} alt={"money"} className={styles.BalanceInfoIcon}/>
                <div>
                    {getBalance(wallet)}
                </div>
            </div>
            <div className={styles.MoneyAdditional}>
                <img src={'images/money_additional.png'} alt={"money"} className={styles.BalanceInfoIcon}/>
                <div>
                    {wallet ? wallet?.cryptocoins.toFixed(4) : 0}
                </div>
            </div>
        </div>
    );
};

export default HeaderPanel;
