// @ts-ignore
import styles from './TaskPage.module.css'
import React, {useEffect, useState} from "react";
import { UserTask } from "../../models/UserTask"
import {useTranslation} from "react-i18next";
import {getUserTasks} from "../../services/getUserTasks";
import {iconMask} from "../../components/iconMask/iconMask";
import TaskModal from "../../components/TaskModal/TaskModal";
import useTelegramUserId from '../../services/getUserId';
import FillDown from "../../components/FillDown/FillDown";

const TaskPage = () => {
    const { t } = useTranslation();
    const [tasks, setTasks] = useState<UserTask[]>([]);
    const [activeTask, setTask] = useState<UserTask | null>(null)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const telegramId = useTelegramUserId();

    const openModal = ({ task }: { task: UserTask}) => {
        setTask(task); // Зберігаємо вибране завдання
        setIsModalOpen(true); // Відкриваємо модалку
    };
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const tasksTemp = await getUserTasks(telegramId);
                setTasks(tasksTemp);
            } catch (error) {
                console.error('Failed to fetch tasks:', error);
            }
        };

        fetchTasks();
    }, [telegramId]);


    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        const showElementsPartTwo = () => {
            setIsLoaded(true);
        }

        const showElements = () => {
            if (document.fonts) {
                document.fonts.ready.then(() => {
                    showElementsPartTwo()
                    ;
                });
            } else {
                window.onload =showElementsPartTwo ;
            }
        };

        const checkAllImagesLoaded = () => {
            const images = document.querySelectorAll('img');
            let loadedCount = 0;

            images.forEach((img) => {
                if (img.complete) {
                    loadedCount++;
                } else {
                    img.onload = () => {
                        loadedCount++;
                        if (loadedCount === images.length) {
                            showElements();
                        }
                    };
                    img.onerror = () => {
                        loadedCount++;
                        if (loadedCount === images.length) {
                            showElements();
                        }
                    };
                }
            });

            if (loadedCount === images.length) {
                showElements();
            }
        };

        checkAllImagesLoaded();
    }, []);

    return (
        <div className={styles.container}>
            <div className={` ${styles.content} ${!isLoaded ? styles.hidden: ""}`}>
                <img src={'images/money_gold.png'} alt={"money"} className={styles.MoneyTitle}/>
                <p className={styles.taskTitle}>
                    {t('task_title')}
                </p>
                <p className={styles.taskDescription}>
                    {t('task_description')}
                </p>
                {tasks.map((task, index) => (
                    <div className={styles.TaskBlock} style={{ animationDelay: `${index * 0.15}s` }}>
                        <div className={styles.TaskIcon}>
                            <img src={task.image} className={styles.TaskIconImg} alt={"icon"}/>
                        </div>
                        <div className={styles.TaskContent}>
                            <div className={styles.TaskName}>
                                {task.name}
                            </div>
                            <div className={styles.TaskReward}>
                                <div className={styles.TaskCost}>
                                    + {task.points}
                                </div>
                                <img src={'images/money_gold.png'} alt={"money"} className={styles.TaskMoney}/>
                            </div>
                        </div>
                        <div className={styles.TaskStatus}  onClick={() => openModal({ task: task})}>
                            {task ? (
                                task.status === 0 ? (
                                    <div className={`${styles.TaskStatusButton} ${styles.TaskStatusActive}`}>
                                        <div className={styles.TaskStatusIcon} style={iconMask('images/arrow_right.png')}/>
                                    </div>) :
                                task.status === 1 ?(
                                    <div className={`${styles.TaskStatusButton} ${styles.TaskStatusDone}`}>
                                        <div className={styles.TaskStatusIcon} style={iconMask('images/done.png')}/>
                                    </div>) :
                                task.status === 2 ?(
                                    <div className={`${styles.TaskStatusButton} ${styles.TaskStatusClaim}`}>
                                        <div className={styles.TaskStatusIcon} style={iconMask('images/money_base.png')}/>
                                    </div>
                            ) : null) : null}
                        </div>
                    </div>
                ))}
                <FillDown/>
                <TaskModal isOpen={isModalOpen} onClose={closeModal} task={activeTask}/>
            </div>
        </div>
    )
}


export default TaskPage
