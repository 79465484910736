import {useEffect, useState} from "react";
import {getPowerScale} from "../services/getPowerScale";

const usePowerScale = (): [number, () => Promise<void>] => {
    const [powerScale, setPowerScale] = useState(0);

    const fetchPowerScale = async () => {
        try {
            const fetchedScale = await getPowerScale();
            setPowerScale(fetchedScale);  // Оновлюємо стан користувача
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    useEffect(() => {
        if (!powerScale) {
            fetchPowerScale();
        }
    }, []);

    return [powerScale, fetchPowerScale];
};

export default usePowerScale;