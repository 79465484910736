import styles from './MiningPage.module.css'

const MiningPage = () => {

    return (
        <div className={styles.container}>
        </div>
    )
}

export default MiningPage