import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { FarmingState } from '../../enums/FarmingState';
import { FarmingInfo } from '../../models/FarmingInfo';
import { getFarmingTime } from '../../services/getFarmingTime';
import { FarmingTime } from '../../models/FarmingTime';
import { postFarmingTime } from '../../services/postFarmingTime';
import { postClaimFarmReward } from '../../services/postClaimFarmReward';

const fetchFarmingTime = createAsyncThunk(
    'farmingTime/fetchFarmingTime',
    async function name(userId: number) {
        const data = await getFarmingTime(userId);

        return data;
    }
)

const initialState: FarmingInfo = {
    farmingState: FarmingState.NotStarted,
    farmingTime: null,
};

const farmingSlice = createSlice({
    name: 'farming',
    initialState,
    reducers: {
        updateFarmingState: (state) => {
            const farmingTime = state.farmingTime;
            const currentTime = new Date();

            if (farmingTime == null || farmingTime.endTime == null || farmingTime.startTime == null){
                state.farmingState = FarmingState.NotStarted;

                return;
            }
    
            if (currentTime.getTime() > farmingTime.endTime) {
                state.farmingState = FarmingState.Finished

                return;
            }
    
            state.farmingState = FarmingState.InProgress
        },
        startFarm: (state, action: PayloadAction<number>) => {
            const userId = action.payload;

            postFarmingTime(userId);

            state.farmingState = FarmingState.InProgress;
            state.farmingTime = {
                startTime: new Date().getTime(),
                endTime: new Date().getTime() + 3600 * 8 * 1000,
            };
        },
        endFarm: (state, action: PayloadAction<number>) => {
            const userId = action.payload;

            postClaimFarmReward(userId);

            state.farmingState = FarmingState.NotStarted;
            state.farmingTime = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFarmingTime.fulfilled, (state, action: PayloadAction<FarmingTime>) => {
            state.farmingTime = action.payload;

            return state;
        });
    },
});

export { fetchFarmingTime };
export const { updateFarmingState, startFarm, endFarm } = farmingSlice.actions;
export default farmingSlice.reducer;