import { useState, useEffect } from 'react';
// @ts-ignore
import { testUserId } from '../config';
import {TelegramWebApp} from "../models/Telegram";

const useTelegram = (): TelegramWebApp | null => {
    const tg = window?.Telegram?.WebApp;

    useEffect(() => {
        try {
            if (tg) {
                tg.expand()
            }
        } catch (error) {
            console.error('Failed to load Telegram WebApp:', error);
        }
    }, []);

    return tg;
};

export default useTelegram;
