import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./StartPage.module.css";

const StartPage = () => {
    const videoRef = useRef(null);
    const buttonRef = useRef(null); // Додаємо референс для кнопки
    const navigate = useNavigate();
    const [videoHeight, setVideoHeight] = useState(0); // Стейт для висоти відео

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();

            // Вираховуємо висоту відео після його завантаження
            const handleLoadedMetadata = () => {
                setVideoHeight(videoRef.current.offsetHeight);
            };

            // Додаємо обробник події для завантаження відео
            videoRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);
        }
    }, []);

    useEffect(() => {
        // Якщо висота відео змінюється, оновлюємо позицію кнопки
        if (buttonRef.current && videoHeight > 0) {
            buttonRef.current.style.bottom = `${(window.innerHeight - videoRef.current.offsetHeight) / 2 + 10}px`;
            buttonRef.current.style.right = `${(window.innerWidth - videoRef.current.offsetWidth) / 2 + 10}px`;
        }
    }, [videoHeight]);

    const handleSkip = () => {
        localStorage.setItem('skipStart', 'true');
        navigate('/home');
    };

    return (
        <div className={styles.videoContainer}>
            <video ref={videoRef} className={styles.video} muted loop>
                <source src="/start.MOV" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <button ref={buttonRef} className={styles.skipButton} onClick={handleSkip}>
                Skip >
            </button>
        </div>
    );
};

export default StartPage;
