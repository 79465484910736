import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiUrl } from '../../config';

// Тип для зберігання конфігурацій
interface ConfigState {
    data: any | null;
    loading: boolean;
    error: string | null;
    lastFetched: number | null;
}

const initialState: ConfigState = {
    data: null,
    loading: false,
    error: null,
    lastFetched: null,
};

// Асинхронна функція для отримання конфігурацій
export const fetchConfig = createAsyncThunk('config/fetchConfig', async () => {
    const response = await axios.get(`${apiUrl}/config`);
    return response.data; // Повертаємо дані конфігурації
});

// Слайс
const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchConfig.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchConfig.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                state.lastFetched = Date.now(); // Зберігаємо час останнього запиту
            })
            .addCase(fetchConfig.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch';
            });
    },
});

// Selector для отримання конфігурацій
export const selectConfig = (state: { config: ConfigState }) => state.config;

// Функція для перевірки, чи потрібно виконувати запит
export const shouldFetchConfig = (state: { config: { lastFetched: null } }) => {
    const { lastFetched } = state.config;
    const fiveMinutes = 5 * 60 * 1000; // 5 хвилин у мілісекундах
    return !lastFetched || Date.now() - lastFetched > fiveMinutes;
};

export default configSlice.reducer;
