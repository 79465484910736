import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// @ts-ignore
import styles from './NavigationPanel.module.css';
import {iconMask} from "../iconMask/iconMask";
import React from "react";


const NavigationPanel= () => {
    const { t } = useTranslation();

    const handleVibration = () => {
        if (navigator.vibrate) {
            navigator.vibrate(30); // Вібрація на 50 мс
        }
    };

    return (
        <div className={`${styles.navigationPanel} `}>
        {/*<div className={`${styles.navigationPanel} ${!isLoading || isLoadingPanel ? styles.hidden: ''}`}>*/}
            <NavLink
                to="/tasks"
                onClick={() =>
                    handleVibration()
                }
                onMouseDown={(e) => e.preventDefault()}
                className={({isActive}) =>
                    `${styles.navigationPanelButton} ${isActive ? styles.navigationPanelButtonActive : ""}`
                }>
                {({isActive}) => (
                    <>
                        <div
                            className={isActive ? `${styles.navigationPanelButtonIcon} ${styles.navigationPanelButtonIconActive}` : styles.navigationPanelButtonIcon}
                            style={iconMask('images/tasks.png')}
                        ></div>
                        {t('tasks')}
                    </>
                )}
            </NavLink>

            <NavLink
                to="/mining"
                onClick={() => handleVibration()}
                className={({isActive}) =>
                    isActive ? `${styles.navigationPanelButton} ${styles.navigationPanelButtonActive}` : styles.navigationPanelButton
                }>
                {({isActive}) => (
                    <>
                        <div
                            className={isActive ? `${styles.navigationPanelButtonIcon} ${styles.navigationPanelButtonIconActive}` : styles.navigationPanelButtonIcon}
                            style={iconMask('images/mining.png')}
                        ></div>
                        {t('mining')}
                    </>
                )}
            </NavLink>

            <NavLink
                to="/home"
                onClick={() => handleVibration()}
                className={({isActive}) =>
                    isActive ? `${styles.navigationPanelButtonHome} ${styles.navigationPanelButtonActive}` : styles.navigationPanelButtonHome
                }>
                {({isActive}) => (
                    <>
                        <div
                            className={isActive ? `${styles.navigationPanelButtonIconHome} ${styles.navigationPanelButtonIconActive}` : styles.navigationPanelButtonIconHome}
                            style={iconMask('images/home.png')}
                        ></div>
                        {t('home')}
                    </>
                )}
            </NavLink>

            <NavLink
                to="/friends"
                onClick={() => handleVibration()}
                className={({isActive}) =>
                    isActive ? `${styles.navigationPanelButton} ${styles.navigationPanelButtonActive}` : styles.navigationPanelButton
                }>
                {({isActive}) => (
                    <>
                        <div
                            className={isActive ? `${styles.navigationPanelButtonIcon} ${styles.navigationPanelButtonIconActive}` : styles.navigationPanelButtonIcon}
                            style={iconMask('images/friends.png')}
                        ></div>
                        {t('friends')}
                    </>
                )}
            </NavLink>

            <NavLink
                to="/wallet"
                onClick={() => handleVibration()}
                className={({isActive}) =>
                    isActive ? `${styles.navigationPanelButton} ${styles.navigationPanelButtonActive}` : styles.navigationPanelButton
                }>
                {({isActive}) => (
                    <>
                        <div
                            className={isActive ? `${styles.navigationPanelButtonIcon} ${styles.navigationPanelButtonIconActive}` : styles.navigationPanelButtonIcon}
                            style={iconMask('images/wallet.png')}
                        ></div>
                        {t('wallet')}
                    </>
                )}
            </NavLink>


        </div>
    );
};

export default NavigationPanel;
