import { levels } from "../config";

export const calculateLevelProgress = (level: number | undefined, points: number | undefined): number => {
    if (level === undefined || points === undefined) {
        return 0; // Если уровень или очки не определены, вернем 0
    }

    const currentLevel = level;
    const currentPoints = points;

    // @ts-ignore
    const nextLevelPoints = levels[currentLevel + 1];

    if (!nextLevelPoints) {
        // Если следующего уровня не существует, пользователь достиг максимального уровня
        return 100; // Возвращаем 100%, так как пользователь достиг максимального уровня
    }

    // @ts-ignore
    const currentLevelPoints = levels[currentLevel];

    const pointsForNextLevel = nextLevelPoints - currentLevelPoints; // Сколько очков нужно до следующего уровня
    const pointsGained = currentPoints - currentLevelPoints; // Сколько очков уже набрано на текущем уровне
    const progressPercentage = (pointsGained / pointsForNextLevel) * 100; // Прогресс в процентах

    return progressPercentage; // Ограничить значение в диапазоне от 0 до 100
};
