// @ts-ignore
import styles from './FillDown.module.css'

const FillDown = () => {
    return (
        <div className={styles.container}/>
    )
}

export default FillDown;
