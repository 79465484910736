import { Wallet } from "./models/Wallet";

export function getBalance(wallet: Wallet | null) {
    if (wallet == null)
        return 0;

    return parceBalance(wallet.coins);
}

export function parceBalance(value: number){
    return parseFloat(value.toFixed(4));
}