import axios from 'axios';
import {apiUrl} from "../config";

export const getFriendsReward = async (userId: number) => {
    const defaultResponse = 0;

    try {
        const response = await axios.get(`${apiUrl}/referrals/earnings/${userId}`);
        console.log(response.data)
        return response?.data?.titesDifference || 0;
    } catch (error) {
        console.error('Error fetching user data, returning default response:', error);
        return defaultResponse;
    }
};


