import axios from 'axios';
import {apiUrl} from "../config";

export const getWallet = async (userId: number) => {
    const defaultResponse = {
        id: userId,
        coins: 35.5,
        cryptocoins: 13.1,
        totalEarnings: 1000
    };

    try {
        const response = await axios.get(`${apiUrl}/wallets/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user data, returning default response:', error);
        return defaultResponse;
    }
};


