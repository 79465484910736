import axios from 'axios';
import {apiUrl} from "../config";

export const getPowerScale = async () => {
    const defaultResponse = {
        power_scale: 0
    };

    try {
        const response = await axios.get(`${apiUrl}/power-scale`);
        const data = response.data;
        if (data) {
            if(data.power_scale) {
                return data.power_scale
            }
        }
        return 0
    } catch (error) {
        console.error('Error fetching power scale data, returning default response:', error);
        return defaultResponse; // Повертаємо дефолтні дані, якщо сталася помилка
    }
};


