// @ts-ignore
import styles from "./HeaderHome.module.css";
import { useState, useEffect } from "react";
import ProgressBar from "../ProgressBar/ProgressBar";
import { iconMask } from "../iconMask/iconMask"
import { calculateLevelProgress } from "../../services/calculateLevelProgress";
import useUser from "../../hooks/useUser";
import usePowerScale from "../../hooks/usePowerScale";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { calculateLevel } from "../../services/calculateLevel";

const HeaderPanel = () => {
    const [user, fetchUser] = useUser()
    const [isLoading, setIsLoading] = useState(false);
    const [levelProgress, setLevelProgress] = useState(0);
    const [userlevel, setUserLevel] = useState(0);
    const [powerScale, fetchPowerScale] = usePowerScale();
    const wallet = useSelector((state: RootState) => state.wallet);

    useEffect(() => {
        setUserLevel(calculateLevel(wallet.coins));

        const perseteges = calculateLevelProgress(userlevel, wallet.coins);

        setLevelProgress(perseteges);

    }, [wallet.coins, userlevel, levelProgress]);

    useEffect(() => {

        const showElements = () => {
            setIsLoading(true)
        };

        if (document.fonts) {
            document.fonts.ready.then(() => {
                showElements();
            });
        } else {
            window.onload = showElements;
        }
    }, []);


    return (
        <div className={` ${styles.headerPanel} ${!isLoading ? styles.hidden : ''}`}>
            <div className={styles.profile}>
                <div className={styles.profileContent}>
                    <div className={styles.profileIcon}>
                        <div className={styles.profileContentName}>
                            {user ? user.username ? user.username.charAt(0) : 'U' : 'U'}
                        </div>

                    </div>
                    <div className={styles.profileContentMain}>
                        <div className={styles.profileContentName}>
                            {user ? user.username.slice(0, 8) : ''}
                        </div>
                        <div className={styles.profileContentLevel}>
                            LvL {userlevel}
                        </div>
                    </div>
                </div>
                <div className={styles.levelProgress}>
                    <ProgressBar progress={levelProgress} />
                </div>
            </div>
            <div className={styles.balance}>
                <div className={styles.balancePanel}>
                    <div className={styles.balanceInfo}>
                        <div style={iconMask('images/money_base.png')} className={styles.BalanceInfoIcon} />
                        <div className={styles.BalanceEarnPerHour}>
                            <p className={styles.BalanceEarnPerHourText}>
                                CEXP
                            </p>
                            <p className={styles.BalanceEarnPerHourMoney}>
                                {user == null ? 0 : user.power}
                            </p>
                        </div>
                    </div>
                    <div className={styles.airDrop}>
                        <div style={iconMask('images/airdrop.png')} className={styles.airDropIcon} />
                        <div className={styles.airDropText}>
                            Airdrop
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderPanel;
