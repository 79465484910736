import axios from 'axios';
import { apiUrl } from "../../config";
import { TaskStatus } from '../../enums/TaskStatus';

export const postClaimTaskReward = async (userId: number, taskId: number, taskStatus: TaskStatus) => {
    try {
        await axios.put(`${apiUrl}/user-tasks/${userId}/${taskId}/${taskStatus}/change-status`);
        await axios.post(`${apiUrl}/wallets/claim-reward/${userId}/${taskId}`);
    } catch (error) {
        console.error('Error fetching claim task reward:', error);
    }
};