import axios from 'axios';
import {apiUrl} from "../config";

export const getFarmingTime = async (userId: number) => {
    const defaultResponse = {
        "startTime": null,
        "endTime": null
    };

    try {
        const response = await axios.get(`${apiUrl}/farm/time/${userId}`);
        const data = response.data;
        return {
            "startTime": data && data?.startTime ? new Date(data.startTime.toLocaleString()).getTime() : null,
            "endTime": data && data?.endTime ? new Date(data.endTime.toLocaleString()).getTime() : null
        };
    } catch (error) {
        console.error('Error fetching user data, returning default response:', error);
        return defaultResponse;
    }
};


