import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import styles from "./MainRouter.module.css";
import NavigationPanel from "../components/NavigationPanel/NavigationPanel";
import FriendPage from "../pages/Friend/FriendPage";
import HomePage from "../pages/Home/HomePage";
import MiningPage from "../pages/Mining/MiningPage";
import WalletPage from "../pages/Wallet/WalletPage";
import TaskPage from "../pages/Task/TaskPage";
import StartPage from "../pages/Start/StartPage"; // Оновлений StartPage для кнопки Skip
import React, { memo, useEffect, useState } from "react";
import Background from "../components/Background/Background";
import FillDown from "../components/FillDown/FillDown";
import BackgroundMusic from "../components/BackgroundMusic/BackgroundMusic";

const MainRouter = () => {
    const location = useLocation();
    const [isPlayMusic, setIsPlayMusic] = useState(false);
    const [isLoadingPanel, setIsLoading] = useState(false);

    // Перевірка стану skip з localStorage
    const isSkippedStart = false;

    const PlayMusic = () => {
        if (!isPlayMusic) {
            setIsPlayMusic(true);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 300);

        return () => clearTimeout(timer);
    }, [location.pathname]);

    return (
        <>
            <div className={styles.container} onMouseEnter={PlayMusic}>
                <Background/>
                <div className={styles.content}>
                     <BackgroundMusic isPlayMusic={isPlayMusic}/>
                    <Routes>
                        {/* Якщо пропустили StartPage - редірект на /home */}
                        <Route
                            path="/"
                            element={isSkippedStart ? <Navigate to="/home" /> : <Navigate to="/start" />}
                        />
                        <Route path="/friends" element={<FriendPage />} />
                        <Route path="/home" element={<HomePage />} />
                        <Route path="/mining" element={<MiningPage />} />
                        <Route path="/wallet" element={<WalletPage />} />
                        <Route path="/tasks" element={<TaskPage />} />
                        <Route path="/start" element={<StartPage />} />
                    </Routes>
                    {location.pathname === "/friends" && <FillDown />}
                </div>
                {location.pathname !== "/start" && <><NavigationPanel isLoadingPanel={isLoadingPanel} /></>}
            </div>
        </>
    );
};

export default memo(MainRouter);
