import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/translation.json';
import ua from './locales/ua/translation.json';

// Налаштування i18next
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            ua: { translation: ua }
        },
        fallbackLng: 'en', // Якщо не знайдено мови, використовує англійську
        lng: 'en', // Початкова мова
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
