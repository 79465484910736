import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Wallet } from '../../models/Wallet'
import { getWallet } from "../../services/getWallet";

const initialState: Wallet = {
    userId: 0,
    coins: 0,
    cryptocoins: 0,
    totalEarnings: 0,
};

const fetchWallet = createAsyncThunk<Wallet, number>(
    'wallet/fetchWallet',
    async (userId: number) => {
        const data = await getWallet(userId);
        console.log(data)
        return data;
    }
);


const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        addCoins: (state, action: PayloadAction<number>) => {
            state.coins += action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWallet.fulfilled, (state, action: PayloadAction<Wallet>) => {
           return action.payload;
        });
    },
});

export const { addCoins } = walletSlice.actions;
export { fetchWallet };
export default walletSlice.reducer;