// @ts-ignore
import styles from './WalletPage.module.css'
import HeaderWallet from "../../components/Header/HeaderWallet";
import React, {useEffect, useState} from "react";
import SwapWallet from "../../components/SwapWallet/SwapWallet";
import {getRate} from "../../services/getRate";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {fetchConfig, selectConfig, shouldFetchConfig} from "../../store/slices/configSlice";
import {AppDispatch, RootState} from "../../store/store";
import {swapWallet} from "../../services/swapWallet";
import useTelegramUserId from "../../services/getUserId";
import useWallet from "../../hooks/useWallet";
import {fetchWallet} from "../../store/slices/walletSlice";

const WalletPage = () => {
    const [rate, setRate] = useState(1);
    const [percent, setPercent] = useState(1);
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const { data, loading, error } = useSelector(selectConfig);
    const userId = useTelegramUserId()
   const wallet = useSelector((state: RootState) => state.wallet);
    const [swap, setSwap] = useState(false);


    useEffect(() => {
        if (shouldFetchConfig({ config: { lastFetched: null } })) { // Тут можна передати state з useSelector
            // @ts-ignore
            dispatch(fetchConfig());
        }
    }, [dispatch]);


    const fetchRate = async () => {
        const tempRate = await getRate();
        setRate(data.exchangeRates ? data.exchangeRates?.cryptoToCoins : tempRate?.rate);
        setPercent(tempRate.percent);
    }

    useEffect(() => {
        fetchRate()
    }, [swap])

    const [isLoaded, setIsLoaded] = useState(false)
    const [goElements, setGoElements] = useState(false)

    useEffect(() => {
        const showElementsPartTwo = () => {
            setIsLoaded(true);
            setTimeout(() => {
                setGoElements(true)
            }, 500)

        }

        const showElements = () => {
            if (document.fonts) {
                document.fonts.ready.then(() => {
                    showElementsPartTwo()
                    ;
                });
            } else {
                window.onload =showElementsPartTwo ;
            }
        };

        const checkAllImagesLoaded = () => {
            const images = document.querySelectorAll('img');
            let loadedCount = 0;

            images.forEach((img) => {
                if (img.complete) {
                    loadedCount++;
                } else {
                    img.onload = () => {
                        loadedCount++;
                        if (loadedCount === images.length) {
                            showElements();
                        }
                    };
                    img.onerror = () => {
                        loadedCount++;
                        if (loadedCount === images.length) {
                            showElements();
                        }
                    };
                }
            });

            if (loadedCount === images.length) {
                showElements();
            }
        };

        checkAllImagesLoaded();
    }, []);

    const swapWallets = async (from: number, to: number) => {
        await swapWallet(userId, Number(!swap ? from.toFixed(4) : to.toFixed(4)), !swap)
        await dispatch(fetchWallet(userId));
    }

    return (
        <div className={styles.container}>
            <div className={` ${styles.content} ${!isLoaded ? styles.hidden: ""}`}>

                <HeaderWallet
                    wallet={wallet}
                />
                <div className={styles.ExchangeRate}>
                    <div className={styles.RateInfo}>
                        <p>
                            {t('exchange_rate')}
                        </p>
                        <div className={styles.RateContent}>
                            <p className={styles.RateInfoValue}>
                                {rate}
                            </p>
                            <p className={percent >= 0 ? styles.RatePercentUp: styles.RatePercentDown}>
                                {percent >= 0 ? "+": null} {percent}%
                            </p>
                        </div>
                    </div>
                    <div className={styles.ExchangeSwap}>
                        <img src={`${!swap ? "images/money_gold.png":"images/money_additional.png"}`} alt={"money"} className={styles.BalanceInfoIcon}/>
                        {">"}
                        <img src={`${swap ? "images/money_gold.png":"images/money_additional.png"}`} alt={"money"} className={styles.BalanceInfoIcon}/>

                    </div>
                </div>
                <SwapWallet exchangeRate={rate} goElements={goElements} swapWallets={swapWallets} swap={swap} setSwap={setSwap}
                        wallet={wallet}/>


            </div>
        </div>
    )
}

export default WalletPage
