import axios from 'axios';
import { apiUrl } from "../config";
import { Friend, FriendResponse } from "../models/Friend";

export const getFriendsInfo = async (userId: number) => {
    try {
        const response = await axios.get(`${apiUrl}/referrals/${userId}`);
        const data = response.data;

        const friendsData: Friend[] = data.map((friend: FriendResponse) => ({
            image_url: "https://example.com/image1.png",
            crypto_balance: 0,
            balance: friend.totalEarnings,
            username: friend.username,
        }));

        return friendsData;
    } catch (error) {
        console.error('Error fetching friends data, returning default response:', error);
        return null;
    }
};
