import React, { useEffect, useRef } from 'react';

const BackgroundMusic = ({ isPlayMusic }) => {
    const audioRef = useRef(null);

    useEffect(() => {
        // Ensure the audio is looped
        if (audioRef.current) {
            audioRef.current.loop = true;
        }

        // Only attempt to play if isPlayMusic is true
        if (isPlayMusic) {
            // Try to play the audio after user interaction
            const playMusic = () => {
                if (audioRef.current) {
                    audioRef.current.play().catch(error => {
                        console.error("Autoplay failed: ", error);
                    });
                }
            };

            // Play audio immediately if interaction already happened
            if (document.userActivation && document.userActivation.hasBeenActive) {
                playMusic();
            } else {
                // If no interaction yet, wait for a click event to play the audio
                document.addEventListener('click', playMusic, { once: true });
            }
        }
    }, [isPlayMusic]);

    return <audio ref={audioRef} src="/music/bg.mp3" />;
};

export default BackgroundMusic;
