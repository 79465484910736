import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getFriendsReward } from "../../services/getFriendsReward";

type FriendsRewardState = number;

const initialState: FriendsRewardState = 0;

const fetchFriendsEarnReward = createAsyncThunk(
    'friendsReward/fetchFriendsReward',
    async (userId: number) => {
        const data = await getFriendsReward(userId);
        return data;
    }
);

// Slice для винагороди друзів
const friendsRewardSlice = createSlice({
    name: 'friendsReward',
    initialState,
    reducers: {
        addCoins: (state, action: PayloadAction<number>) => {
            return state + action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFriendsEarnReward.fulfilled, (state, action: PayloadAction<number>) => {
            return action.payload;
        });
    },
});

export const { addCoins } = friendsRewardSlice.actions;
export { fetchFriendsEarnReward };
export default friendsRewardSlice.reducer;
