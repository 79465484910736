import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import i18n from './i18n';

import MainRouter from "./router/MainRouter.jsx"
import {useEffect} from "react";
import {getUser} from "./services/getUser";
import useTelegramUserId from "./services/getUserId";
import { useDispatch } from 'react-redux';
import { fetchWallet } from './store/slices/walletSlice';
import {fetchFarmingTime} from './store/slices/farmingSlice';
import {fetchFriendsEarnReward} from "./store/slices/friendsRewardSlice";

function App() {
    const userId = useTelegramUserId();
    const dispatch = useDispatch(); 

    useEffect(() => {
        const initializeLanguage = async () => {
            const user = await getUser(userId);
            i18n.changeLanguage(user.language);
        };
        initializeLanguage();
    }, []);

    useEffect(() => {
        dispatch(fetchWallet(userId));
        dispatch(fetchFarmingTime(userId));
        dispatch(fetchFriendsEarnReward(userId));
    }, [dispatch])

  return (
      <>
          <Router>
            <MainRouter />
          </Router>
      </>
  );
}

export default App;
