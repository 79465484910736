import React from 'react';
import { Box, LinearProgress, styled } from '@mui/material';
// @ts-ignore
import styles from './ProgressBar.css';

interface ProgressBarProps {
    progress: number;
}

const ProgressBarWrapper = styled(Box)({
    width: '100%',
    backgroundColor: '#4B282860',
    borderRadius: '8px',
});

const CustomLinearProgress = styled(LinearProgress)({
    height: '10px',
    borderRadius: '8px',
    backgroundColor: '#4B2828',
    boxShadow: '0 2px 2px #000000, inset 0 1px 2px #9F5353', // Комбіновані тіні
    '& .MuiLinearProgress-bar': {
        background: 'linear-gradient(90deg, #8C5E03, #F1D544)',
        borderRadius: "10px",

    },
});


const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <Box width="100%" mt={1}>
                {/*<LinearProgress variant="determinate" value={progress} />*/}
                <ProgressBarWrapper>
                    <CustomLinearProgress variant="determinate" value={progress} />
                </ProgressBarWrapper>
            </Box>
        </Box>
    );
};


export default ProgressBar;
