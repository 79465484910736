import axios from 'axios';
import {apiUrl} from "../config";

export const claimFriendsReward = async (userId: number) => {
    const defaultResponse = {
        difference: 0
    };

    try {
        const response = await axios.post(`${apiUrl}/referrals/add-earnings/${userId}`,
            {
                referredId: userId
            });
        return response?.data?.difference;
    } catch (error) {
        console.error('Error fetching user data, returning default response:', error);
        return defaultResponse;
    }
};


