import { UserTask, UserTaskResponse } from "../models/UserTask";
import { apiUrl } from "../config";
import axios from 'axios';

const statusMap: { [key: string]: number } = {
    "not completed": 0,
    "completed": 1,
    "in progress": 2
};

export const getUserTasks = async (userId: number): Promise<UserTask[]> => {

    try {
        const response = await axios.get(`${apiUrl}/user-tasks/${userId}`);
        const data = response.data;

        const userTasks: UserTask[] = data
            .map((task: UserTaskResponse) => {
                return {
                    id: task.userTaskId,
                    task_id: task.taskId,
                    user_id: task.userId,
                    status: statusMap[task.status] || 0,
                    image: task.task.imgUrl,
                    name: task.task.title,
                    link_redirect: task.task.redirectUrl,
                    points: task.task.reward
                };
            });

        return userTasks;
    } catch (error) {
        console.error('Error fetching user data, returning default response:', error);
        return [];
    }
};