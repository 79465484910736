import { useState, useEffect } from 'react';
// @ts-ignore
import { testUserId } from '../config';
import {TelegramWebApp} from "../models/Telegram";

declare global {
    interface Window {
        Telegram: {
            WebApp: TelegramWebApp;
        };
    }
}

const useTelegramUserId = (): number => {
    // Одразу беремо userId з window.Telegram при ініціалізації
    const initialUserId = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id ?? testUserId;
    const [userId, setUserId] = useState<number>(initialUserId);

    useEffect(() => {
        try {
            const telegramUserId = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id;
            if (telegramUserId && telegramUserId !== userId) {
                setUserId(telegramUserId); // Оновлюємо, якщо userId змінився
            }

            window?.Telegram?.WebApp?.ready();
        } catch (error) {
            console.error('Failed to load Telegram WebApp user data:', error);
        }
    }, [userId]);

    return userId;
};

export default useTelegramUserId;
