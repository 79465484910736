// @ts-ignore
import styles from './FriendPage.module.css'
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { FriendsReward } from "../../models/FriendsReward";
import { getFriendsInfo } from "../../services/getFriendsInfo";
import { iconMask } from "../../components/iconMask/iconMask";
import FillDown from "../../components/FillDown/FillDown";
import useTelegramUserId from '../../services/getUserId';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../../store/store';
import useUser from '../../hooks/useUser';
import { getBalance, parceBalance } from '../../utils';
import {fetchFriendsEarnReward} from "../../store/slices/friendsRewardSlice";
import {claimFriendsReward} from "../../services/claimFriendsReward";

const FriendPage = () => {
    const { t } = useTranslation();
    const [friendsRewardData, setFriendsRewardData] = useState<FriendsReward | null>(null)
    const userId = useTelegramUserId();
    const wallet = useSelector((state: RootState) => state.wallet);
    const dispatch: AppDispatch = useDispatch();
    const friendsReward = useSelector((state: RootState) => state.friendsReward);
    const [user, fetchUser] = useUser();

    const fetchFriendsReward = async () => {
        return await getFriendsInfo(userId);
    }



     const fetchData = async () => {
            dispatch(fetchFriendsEarnReward(userId));
            const friendsData = await fetchFriendsReward();
            const totalBalance = friendsData?.map(friend => friend.balance)
                .reduce((acc, balance) => acc + balance, 0);
            const friendReward: FriendsReward = {
                reward: friendsReward == null ? 0 : friendsReward,
                crypto_balance_pool: Number(wallet.cryptocoins.toFixed(4)),
                balance_pool:  Number(getBalance(wallet).toFixed(4)),
                mining_power: user == null ? 0 : user.power,
                friends: friendsData == null ? [] : friendsData,
            };

            setFriendsRewardData(friendReward);
        };

    const claimReward = async () => {
        if (friendsRewardData && friendsRewardData?.reward > 0) {
            await claimFriendsReward(userId);
            await fetchData()
        }
    }

    useEffect(() => {

        fetchData();
    }, [user]);


    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <p className={styles.friendsTitle}>
                    {t('friends_title')}
                </p>
                <p className={styles.friendsDescription}>
                    {t('friends_description')}
                </p>
                <div className={styles.RewardContainer}>
                    <div className={styles.RewardPoolContainer}>
                        <div className={styles.ContentPool}>
                            <p className={styles.RewardPoolTitle}>
                                {t('mining_pool')}
                            </p>
                            <div className={styles.PoolRewardInfo}>
                                <img src={"/images/money_gold.png"} alt={"money"}
                                    className={styles.PoolRewardMoney} />
                                <div className={styles.PoolRewardInfoTwo}>
                                    <p>
                                        {t('your_reward')}
                                    </p>
                                    <p>
                                        {friendsRewardData ? parceBalance(friendsRewardData.reward) : 0.0}
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className={`${styles.RewardClaim} ${friendsRewardData && friendsRewardData?.reward > 0 ? styles.RewardClaimActive : ""}`}
                        onClick={() => claimReward()}>
                            {t('get')}
                        </div>

                    </div>
                    <div className={styles.FriendsStats}>
                        <div className={styles.FriendsStat}>
                            <p className={styles.FriendsStatTitle}>{t('crypto_balance_pool')}</p>
                            <p className={`${styles.FriendsStatEarn} ${styles.FriendsStatEarnCrypto}`}>{friendsRewardData ? friendsRewardData.crypto_balance_pool : 0}</p>
                            <img src={"/images/money_additional.png"} alt={"money"}
                                className={styles.FriendsStatImage} />
                        </div>
                        <div className={styles.FriendsStat}>
                            <p className={styles.FriendsStatTitle}>{t('balance_pool')}</p>
                            <p className={`${styles.FriendsStatEarn}`}>{friendsRewardData ? friendsRewardData.balance_pool : 0}</p>
                            <img src={"/images/money_gold.png"} alt={"money"}
                                className={styles.FriendsStatImage} />
                        </div>
                        <div className={styles.FriendsStat}>
                            <p className={styles.FriendsStatTitle}>{t('mining_power')}</p>
                            <p className={`${styles.FriendsStatEarn}`}>{friendsRewardData ? friendsRewardData.mining_power : 0}</p>
                            <p className={`${styles.FriendsStatCEXP}`}>CEXP</p>

                        </div>
                    </div>

                </div>
                {friendsRewardData && friendsRewardData.friends.length > 0 ?
                    <div className={styles.FriendsContainer}>
                        <p className={styles.FriendsListTitle}>
                            {t('my_friends_title', { friends_count: friendsRewardData.friends.length })}
                        </p>
                        <div className={styles.MyFriends}>
                            {friendsRewardData ? friendsRewardData.friends.map((friend, index) => (
                                <div key={index} className={styles.MyFriend} style={{ animationDelay: `${index * 0.15}s` }} >
                                    <div className={styles.MyFriendImage}>
                                        {friend.username.charAt(0).toUpperCase()}
                                    </div>
                                    <div className={styles.MyFriendContent}>
                                        <div className={styles.MyFriendName}>
                                            {friend.username}
                                        </div>
                                        <div className={styles.MyFriendBalance}>
                                            <div className={styles.MyFriendGameBalance}>
                                                <p className={styles.MyFriendGameBalanceValue}>
                                                    + {parceBalance(friend.balance)}
                                                </p>
                                                <img src={"images/money_gold.png"} alt={"money"} className={styles.MyFriendMoneyImage} />
                                            </div>
                                            {/* <div className={styles.MyFriendCryptoBalance}>
                                                <p className={styles.MyFriendGameBalanceValue}>
                                                    + {parceBalance(friend.crypto_balance)}
                                                </p>
                                                <img src={"images/money_additional.png"} alt={"money"}
                                                    className={styles.MyFriendMoneyImage} />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            )) : null}
                            <FillDown />
                        </div>
                    </div>
                    :
                    <div className={styles.NoFriends}>
                        <div className={styles.NoFriendsIcon} style={iconMask('images/no_friends.png')} />
                    </div>
                }
            </div>
        </div>
    )
}


export default FriendPage