import React, {SetStateAction, useEffect, useState} from 'react';
// @ts-ignore
import styles from './SwapWallet.module.css';
import {iconMask} from "../iconMask/iconMask";
import {useTranslation} from "react-i18next";
import {swapWallet} from "../../services/swapWallet";
import useTelegramUserId from "../../services/getUserId";
import {Wallet} from "../../models/Wallet"; // Імпортуємо стилі

interface SwapContainerProps {
    exchangeRate: number;
    goElements: boolean;
    swapWallets: (from: number, to: number) => void;
    swap: boolean,
    setSwap: SetStateAction<any>,
    wallet: Wallet | null
}

const SwapWallet: React.FC<SwapContainerProps> = ({ exchangeRate, goElements, swapWallets, swap, setSwap, wallet}) => {
    const [from, setFrom] = useState<number>(0);
    const [to, setTo] = useState<number>(0);
    const [isVisible, setIsVisible] = useState(true);
    const { t } = useTranslation();
    const userId = useTelegramUserId();

    const MIN_VALUE = 0.000001;

    const setToWrapper = async (to: number | null) => {
        const tempValue = to && to !== 0 
            ? Math.max(parseFloat((Math.round(to * 1000000) / 1000000).toString()), MIN_VALUE) 
            : 0;
        setTo(tempValue);
    }
    
    const setFromWrapper = async (from: number | null) => {
        const tempValue = from && from !== 0 
            ? Math.max(parseFloat((Math.round(from * 1000000) / 1000000).toString()), MIN_VALUE) 
            : 0;
        setFrom(tempValue);
    }

    const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newFromValue = parseFloat(e.target.value) || 0; // Якщо не введено значення, встановлюємо 0
        setFromWrapper(newFromValue);
        e.target.value = (parseFloat(e.target.value) || 0).toString()
        setToWrapper(newFromValue ? !swap ? newFromValue / exchangeRate : newFromValue * exchangeRate : 0); // Перерахунок 'to' по курсу
    };

    const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newToValue = parseFloat(e.target.value) || 0; // Якщо не введено значення, встановлюємо 0
        setToWrapper(newToValue);
        e.target.value = (parseFloat(e.target.value) || 0).toString()
        setFromWrapper(newToValue ? !swap ? newToValue * exchangeRate : newToValue / exchangeRate: 0); // Перерахунок 'from' по курсу
    };


    const setToMax = async() => {
    if (wallet) {
        const maxTo = wallet.cryptocoins;  // Максимальна кількість криптомонет
        await setToWrapper(maxTo);  // Встановлюємо значення для 'to'
        await setFromWrapper(!swap ? maxTo * exchangeRate: maxTo / exchangeRate);  // Конвертуємо криптомонети у звичайні монети
    }
};

    const setFromMax = async() => {
        await setFromWrapper(wallet ? wallet.coins: 0)
        await setToWrapper(wallet ? !swap ? wallet.coins / exchangeRate: wallet.coins * exchangeRate: 0); // Перерахунок 'to' по курсу
    }

    const handleSwap = () => {
        setIsVisible(false);

        setTimeout(() => {
            setSwap(!swap);

            setIsVisible(true);
        }, 300);
    };

    return (
        <>
        <div className={styles.swapContainer}>
            {!swap ? (
                    <div
                        className={`${styles.swapBox} ${styles.fromBox} ${isVisible ? styles.visible : styles.hidden} ${!goElements ? styles.hiddenLeft : ""}`}>
                        <div className={styles.swapContent}>
                            <div className={styles.swapLabel}>From</div>
                            <input
                                type="number"
                                className={styles.swapInput}
                                value={from !== null ? from : ""}
                                onChange={handleFromChange}
                            />
                        </div>
                        <div className={`${styles.maxContainer} ${styles.maxContainerGold}`}
                        onClick={setFromMax}>
                            <img src={'images/money_gold.png'} alt="from-icon" className={styles.icon}
                                 />
                            Max
                        </div>
                    </div>
                ) :
                <div
                    className={`${styles.swapBox} ${styles.toBox} ${isVisible ? styles.visible : styles.hidden} ${!goElements ? styles.hiddenLeft : ""}`}>
                    <div className={styles.swapContent}>
                        <div className={styles.swapLabel}>From</div>
                        <input
                            type="number"
                            className={styles.swapInput}
                            value={to !== null? to: ""}
                            onChange={handleToChange}
                        />
                    </div>
                    <div className={`${styles.maxContainer} ${styles.maxContainerSilver}`}
                    onClick={setToMax}>
                    <img src={'images/money_additional.png'} alt="to-icon" className={styles.icon} />
                        Max
                    </div>
                        </div>
            }

            <div className={`${styles.swapButton}  ${!goElements ? styles.hiddenView : ""}`} onClick={handleSwap}>
                <div className={styles.SwapIcon} style={iconMask('images/swap.png')}/>
            </div>
            {swap ? (
                    <div
                        className={`${styles.swapBox} ${styles.fromBox} ${isVisible ? styles.visible : styles.hidden} ${!goElements ? styles.hiddenRight : ""}`}>
                        <div className={styles.swapContent}>
                            <div className={styles.swapLabel}>To</div>
                            <input
                                type="number"
                                className={styles.swapInput}
                                value={from !== null ? from: ""}
                                onChange={handleFromChange}
                            />
                        </div>
                        <div className={`${styles.maxContainer} ${styles.maxContainerGold}`}
                        onClick={setFromMax}>
                        <img src={'images/money_gold.png'} alt="from-icon" className={styles.icon} />
                            Max
                        </div>
                    </div>
                ) :
                <div
                    className={`${styles.swapBox} ${styles.toBox} ${isVisible ? styles.visible : styles.hidden} ${!goElements ? styles.hiddenRight : ""}`}>
                    <div className={styles.swapContent}>
                        <div className={styles.swapLabel}>To</div>
                        <input
                            type="number"
                            className={styles.swapInput}
                            value={to !== null ? to: ""}
                            onChange={handleToChange}
                        />
                    </div>
                        <div className={`${styles.maxContainer} ${styles.maxContainerSilver}`}
                        onClick={setToMax}>
                    <img src={'images/money_additional.png'} alt="to-icon" className={styles.icon} />
                            Max
                        </div>
                </div>
            }
        </div>
    <div className={styles.ConverationContainer}>
        <div className={styles.Convertation} onClick={() => swapWallets(from ? from : 0, to ? to : 0)}>
            {t('convertation')}
        </div>
    </div>
    </>
    );
};

export default SwapWallet;
