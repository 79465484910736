import {useEffect, useState} from "react";
import {getUser} from "../services/getUser";
import {User} from "../models/User";
import useTelegramUserId from "../services/getUserId";

const useUser = (): [User | null, () => Promise<void>] => {
    const userId = useTelegramUserId();
    const [user, setUser] = useState<User | null>(null);

    const fetchUser = async () => {
        try {
            const fetchedUser = await getUser(userId);
            setUser(fetchedUser);  // Оновлюємо стан користувача
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    useEffect(() => {
        if (!user) {
            fetchUser();
        }
    }, [userId, user]);

    return [user, fetchUser];
};

export default useUser;