import React from 'react';
// @ts-ignore
import styles from './TaskModal.module.css';
import { UserTask } from "../../models/UserTask";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from 'react';
import { postClaimTaskReward } from '../../services/api/claimTask';
import useTelegramUserId from '../../services/getUserId';
import { useDispatch } from 'react-redux';
import { TaskStatus } from '../../enums/TaskStatus';
import { addCoins } from '../../store/slices/walletSlice';
import useTelegram from "../../hooks/useTelegram";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    task: UserTask | null;
}

const TaskModal: React.FC<ModalProps> = ({ isOpen, onClose, task }) => {
    const [taskStatus, setTask] = useState(Number);
    const { t } = useTranslation();
    const tg = useTelegram();
    const userId = useTelegramUserId();
    const dispatch = useDispatch();

    function onClickTask() {
        if(task == null)
            return;

        if (taskStatus == 0) {
            try {
                tg?.openTelegramLink(task.link_redirect);
            }
            catch {
                tg?.openLink(task.link_redirect);
            }
            task.status = 2;
            setTask(2);
        } else if (taskStatus == 2) {
            postClaimTaskReward(userId, task.task_id, TaskStatus.Completed);
            dispatch(addCoins(task.points));

            task.status = 1;

            setTask(1);
        } else if (taskStatus == 1) {
            onClose()
        }
    }

    useEffect(() => {
        if (task == null)
            return;

        setTask(task.status)
    }, [task])

    return (
        <div className={`${styles.blurPage} ${isOpen ? styles.blurPageOpen : ''}`}>

            <div className={`${styles.modal} ${isOpen ? styles.open : ''}`}>
                <div className={styles.modalContent}>
                    {/* Хрестик для закриття */}
                    <button className={styles.closeButton} onClick={onClose}>
                        &times;
                    </button>
                    <div className={styles.TaskIcon}>
                        <img className={styles.TaskIconImg} src={task?.image} alt={"logo"} />

                    </div>
                    <div className={styles.TaskTitle}>
                        {task?.name}
                    </div>
                    <div className={styles.TaskReward}>
                        <div className={styles.TaskCost}>
                            + {task?.points}
                        </div>
                        <img src={'images/money_gold.png'} alt={"money"} className={styles.TaskMoney} />
                    </div>
                    <div className={styles.ButtonContainer}>
                        <div className={styles.Button} onClick={onClickTask}>
                            {taskStatus === 0 ? (
                                t('task_subscribe')
                            ) : taskStatus === 1 ? (
                                t('task_done')
                            ) : taskStatus === 2 ? (
                                t('task_claim')
                            ) : null}
                        </div>

                    </div>

                </div>
            </div>
        </div>

    );
};

export default TaskModal;
