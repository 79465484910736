import { configureStore } from '@reduxjs/toolkit';
import walletReducer from './slices/walletSlice';
import farmingReducer from './slices/farmingSlice';
import configReducer from './slices/configSlice';
import friendsRewardReducer from './slices/friendsRewardSlice';

const store = configureStore({
    reducer: {
        wallet: walletReducer,
        farming: farmingReducer,
        config: configReducer,
        friendsReward: friendsRewardReducer,
    },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
