export const testUserId = 1035443657;
export const apiUrl = "https://lucifer-network.com:3005/api"
export const levels = {
    1: 0,
    2: 150,
    3: 500,
    4: 1000,
    5: 2000,
    6: 3500,
    7: 5000,
    8: 7500,
    9: 10000,
    10: 15000
};