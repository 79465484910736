// @ts-ignore
import styles from './HomePage.module.css'
import React, { useEffect, useState } from "react";
import { iconMask } from "../../components/iconMask/iconMask";
import FarmingButton from "../../components/FarmingButton/FarmingButton";
import HeaderPanel from "../../components/Header/HeaderHome";
import useUser from "../../hooks/useUser";
import {useDispatch, useSelector} from 'react-redux';
import { RootState } from '../../store/store';
import { FarmingState } from '../../enums/FarmingState';
import { getBalance } from '../../utils';
import useTelegramUserId from '../../services/getUserId';
import useTelegram from "../../hooks/useTelegram";
import {fetchConfig, selectConfig, shouldFetchConfig} from "../../store/slices/configSlice";
import {updateFarmingState} from "../../store/slices/farmingSlice";

const HomePage = () => {
    const [user, fetchUser] = useUser()
    const [isLoaded, setIsLoaded] = useState(false)
    const [goElements, setGoElements] = useState(false)
    const wallet = useSelector((state: RootState) => state.wallet);
    const farming = useSelector((state: RootState) => state.farming);
    const userId = useTelegramUserId();
    const tg = useTelegram();
     const dispatch = useDispatch();
    const { data, loading, error } = useSelector(selectConfig);


        useEffect(() => {
            if (shouldFetchConfig({ config: { lastFetched: null } })) { // Тут можна передати state з useSelector
                // @ts-ignore
                dispatch(fetchConfig());
            }
        }, [dispatch]);

    const futureDate = new Date();
    futureDate.setHours(futureDate.getHours() + 1);

    function onShareLink() {
        const referralLink = `https://t.me/LUCIFER_Farming_bot?start=${userId}`
        const shareLinkText = `https://t.me/share/url?url=${referralLink}&text=`;
        if (tg) {
            tg.openTelegramLink(shareLinkText)
        }
        else {
            window.open(shareLinkText, '_blank');
        }
    }

    useEffect(() => {
        dispatch(updateFarmingState())
    }, [farming.farmingTime]);

    useEffect(() => {
        const showElementsPartTwo = () => {
            setIsLoaded(true);
            setTimeout(() => {
                setGoElements(true)
                console.log(farming)
            }, 300)
        }

        const showElements = () => {
            if (document.fonts) {
                document.fonts.ready.then(() => {
                    showElementsPartTwo()
                        ;
                });
            } else {
                window.onload = showElementsPartTwo;
            }
        };

        const checkAllImagesLoaded = () => {
            const images = document.querySelectorAll('img');
            let loadedCount = 0;

            images.forEach((img) => {
                if (img.complete) {
                    loadedCount++;
                } else {
                    img.onload = () => {
                        loadedCount++;
                        if (loadedCount === images.length) {
                            showElements();
                        }
                    };
                    img.onerror = () => {
                        loadedCount++;
                        if (loadedCount === images.length) {
                            showElements();
                        }
                    };
                }
            });

            if (loadedCount === images.length) {
                showElements();
            }
        };

        checkAllImagesLoaded();

    }, []);

    return (
        <div className={styles.container}>
            <div className={` ${styles.content} ${!isLoaded ? styles.hidden : ""}`}>
                <div className={`${styles.BalanceInfo} ${!goElements ? styles.elementsHidden : ""}`}>
                    <p className={styles.BalanceInfoMoney}>
                        {getBalance(wallet)}
                    </p>
                    <img src={'images/money_gold.png'} alt={"money"} className={styles.BalanceInfoIcon} />
                </div>
                <img src={'images/Lucifer.png'}
                    alt={"Lucifer"}
                    className={farming.farmingState === FarmingState.InProgress
                        ? styles.MainHeroFarm
                        : styles.MainHeroIdle} />
                <div className={styles.LowerPanel}>
                    <div />
                    <FarmingButton
                        farmingPower={user && user.power ? user.power : 0}
                        farmingScale={data && data.farmEarningsRate ? data.farmEarningsRate : 0.0001}
                        goElements={goElements} />
                    <div className={` ${styles.Share} ${!goElements ? styles.elementsHidden : ""}`} onClick={onShareLink}>
                        <div className={styles.ShareIcon} style={iconMask('images/share.png')} />
                    </div>
                </div>
            </div>
            <HeaderPanel />
        </div>
    )
}

export default HomePage