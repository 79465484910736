import axios from 'axios';
import {apiUrl} from "../config";

export const getUser = async (userId: number) => {

    const defaultResponse = {
        id: userId,
        username: "",
        level: 13,
        language: "en",
        power: 100,
    };


    try {
        const userResponse = await axios.get(`${apiUrl}/users/${userId}`);
        return {
            id: userId,
            username: userResponse.data.username,
            level: userResponse.data.level,
            language: userResponse.data.language,
            power: userResponse.data.power,
        }
    } catch (error) {
        console.error('Error fetching user data, returning default response:', error);
        return defaultResponse; // Повертаємо дефолтні дані, якщо сталася помилка
    }
};


